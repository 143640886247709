.leaflet-control-locate a {
  font-size: 1.4em;
  color: #444;
  cursor: pointer;
}

.leaflet-control-locate a span {
  line-height: 30px;
}

.leaflet-control-locate.active a {
  color: #2074b6 !important;
}

.leaflet-control-locate.active.following a {
  color: #fc8428 !important;
}

.leaflet-top {
  top: 8vh;
  position: fixed;
}
