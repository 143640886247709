.feeling_button {
  border: 1px solid #000;
  margin: 10px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feeling_image {
  width: 200px;
  height: 200px;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}

/* Portrait orientation */
@media screen and (orientation: portrait) {
  .feelings_container {
    padding: 40px;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(3, 33%);
  }
}

/* Landscape orientation */
@media screen and (orientation: landscape) {
  .feelings_container {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-template-rows: repeat(2, 50%);
  }
}

@media screen and (max-width: 768px) {
  .feelings_container {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-template-rows: repeat(3, auto);
    padding: 20px;
    overflow: auto;
    height: 65vh;
  }

  .feelings_container > div {
    margin-bottom: 20px !important;
  }

  .feeling_image {
    width: 80%;
    height: 80%;
  }
}
