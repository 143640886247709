.attention_container {
  text-align: center;
  font-family: 'Inter', sans-serif;
}

.game_info_container {
  background-color: #e0dfd1;
  padding-top: 90px;
  padding-bottom: 27px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 81px;
  color: #624b4b;
  font-size: 20px;
  box-shadow: 0px 6px 14.7px 0px #00000040;
}

.text_container h3 {
  font-size: 96px;
}

.option_buttons_container {
  display: flex;
  justify-content: space-around;
  padding-bottom: 40px;
}

.option_button {
  text-align: center;
  background-color: #475f81;
  color: white;
  font-size: 48px;
  padding: 20px 74px;
  border-radius: 24px;
  font-weight: bold;
}

/* Screen 600px */
@media screen and (max-width: 768px) {
  .game_info_container {
    padding-top: 40px;
    padding-bottom: 13px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 24px;
    font-size: 16px;
  }

  .text_container h3 {
    font-size: 48px;
  }

  .option_buttons_container {
    padding-bottom: 20px;
  }

  .option_button {
    font-size: 32px;
    padding: 10px 37px;
  }
}
