.modal_container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgb(0 0 0 / 50%) 0px 0px 10px 999999px;
  z-index: 1000;
  background-color: white;
  border-radius: 81px;
  width: 80%;
  animation: fadeIn 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.intro_container {
  text-align: center;
  padding: 83px;
}

.intro_title {
  font-family: 'Inter', sans-serif;
  color: #475f81;
  font-size: 64px;
  font-weight: normal;
}

.skip_button {
  display: block;
  margin: auto;
  padding: 20px;
  font-size: 24px;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

.skip_button img {
  width: 44px;
}

.start_button {
  text-align: center;
  background-color: #475f81;
  color: white;
  font-size: 48px;
  padding: 20px 74px;
  border-radius: 24px;
  font-weight: bold;
}

.good_result_container,
.bad_result_container {
  text-align: center;
  padding: 160px;
}

.good_result_container h1,
.bad_result_container h1 {
  font-family: 'Inter', sans-serif;
  font-size: 64px;
  font-weight: normal;
}

.good_result_container p,
.bad_result_container p {
  font-family: 'Inter', sans-serif;
  font-size: 36px;
}

.good_result_container button,
.bad_result_container button {
  text-align: center;
  background-color: #475f81;
  color: white;
  font-size: 32px;
  padding: 20px 74px;
  border-radius: 24px;
}

/* Scree size 600px */
@media screen and (max-width: 600px) {
  .intro_container {
    padding: 20px;
  }

  .intro_title {
    margin-top: 30px;
    font-size: 32px;
  }

  .skip_button {
    top: 0px;
    right: 0px;
  }

  .skip_button img {
    width: 24px;
  }

  .start_button {
    font-size: 32px;
    padding: 10px 20px;
  }

  .modal_container {
    width: 90%;
    border-radius: 24px;
  }

  .good_result_container,
  .bad_result_container {
    text-align: center;
    padding: 60px;
  }

  .good_result_container img,
  .bad_result_container img {
    width: 100px;
  }

  .good_result_container h1,
  .bad_result_container h1 {
    font-family: 'Inter', sans-serif;
    font-size: 32px;
    font-weight: normal;
  }

  .good_result_container p,
  .bad_result_container p {
    font-family: 'Inter', sans-serif;
    font-size: 24px;
  }

  .good_result_container button,
  .bad_result_container button {
    text-align: center;
    background-color: #475f81;
    color: white;
    font-size: 24px;
    padding: 10px 20px;
    border-radius: 24px;
  }
}
