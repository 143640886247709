.hand {
  height: 200px;
}

.badge {
  width: 100px;
  object-fit: contain;
}

/* Screen max width 768px */
@media (max-width: 768px) {
  .hand {
    height: 100px;
    margin-top: 20px;
  }

  .badge {
    width: 50px;
  }
}
