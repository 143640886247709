.container {
  text-align: center;
  font-family: Inter, sans-serif;
}

.game_info_container {
  background-color: #e0dfd1;
  padding-top: 90px;
  padding-bottom: 27px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 81px;
  color: #624b4b;
  font-size: 20px;
  box-shadow: 0px 6px 14.7px 0px #00000040;
}

.options_container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  flex-direction: column;
  padding: 40px;
}

.option_button {
  text-align: center;
  color: white;
  font-size: 48px;
  padding: 20px;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Screen 600px */
@media screen and (max-width: 768px) {
  .game_info_container {
    padding-top: 30px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    border-radius: 24px;
  }

  .options_container {
    padding: 20px;
  }

  .option_button {
    font-size: 32px;
    padding: 10px;
    width: 100px;
    height: 100px;
  }
}
