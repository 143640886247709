.logout_button {
  border: none;
  background: none;
  font-family: 'Noto sans', sans-serif;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.start_text {
  text-align: center;
  font-family: 'Noto sans', sans-serif;
  font-size: 20px;
  margin-top: 95px;
}

/* screen max width 768px */
@media (max-width: 768px) {
  .logout_button {
    display: block;
    position: relative;
    left: unset;
    bottom: unset;
    transform: unset;
    margin: auto;
    margin-top: 20px;
  }

  .start_text {
    margin-top: 50px;
  }
}
